export default function () {
	const { api, apiVersionHeader } = window.Bark;

  const consentToTCPA = (hashedProjectId, hashedSellerIds) => {
		return api(
			`/${hashedProjectId}/tcpa-consent-given`,
			{
				spidhs: hashedSellerIds,
			},
			() => {},
			() => {},
			'POST',
		);
	};

	const setProjectCertificate = (projectId, hashedSellerIds, trustedFormToken) => {
		return api(
			`/buyer/project/${projectId}/certificate`,
			{
				spidhs: hashedSellerIds,
				trustedFormToken,
			},
			() => {},
			() => {},
			'POST',
			apiVersionHeader('v2'),
		);
	};

	return {
		consentToTCPA,
		setProjectCertificate,
	};
}

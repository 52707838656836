export function SliderWithButtonsComponent() {
  /**
   * Navigates the pagination in the specified direction
   * @param {string} direction - 'left', 'right', or 'none' (for reinitialization)
   * @param {jQuery} scope - The jQuery element containing the pagination
   */
  function navigate(direction, scope) {
    // Get current state
    const nMoved = scope.data('moved') || 0;
    const viewWidth = scope.width();
    const $pageElements = $('.page-element', scope);
    const cardWidth = $pageElements.first().width();
    const nItems = $pageElements.length;
    const nToMoveUncalc = Math.floor(viewWidth / cardWidth);

    let moved;

    // Determine how many items to move and the new position
    switch (direction) {
      case 'left':
        const nToMoveLeft = Math.min(nToMoveUncalc, nMoved);
        moved = Math.max(0, nMoved - nToMoveLeft);
        break;
      case 'right':
        const nToMoveRight = Math.min(nToMoveUncalc, nItems - nToMoveUncalc - nMoved);
        moved = nMoved + nToMoveRight;
        break;
      case 'none':
        // Used for initialization or window resize
        moved = Math.min(nMoved, nItems - nToMoveUncalc);
        moved = Math.max(0, moved); // Ensure moved is never negative
        break;
      default:
        console.error(`Unknown direction: ${direction}`);
        return;
    }

    // Update state
    scope.data('moved', moved);

    // Update UI
    $pageElements.css({
      transform: `translateX(-${moved * 100}%)`
    });

    // Update navigation buttons state
    const $nextButton = $('.next-page', scope);
    const $prevButton = $('.previous-page', scope);
    const $pageButtons = $('.page-buttons', scope);

    // Disable/enable navigation buttons
    $nextButton.toggleClass('disabled', moved >= (nItems - nToMoveUncalc));
    $prevButton.toggleClass('disabled', moved < 1);

    // Hide controls if not enough items to paginate
    $pageButtons.toggleClass('hidden', nItems <= nToMoveUncalc);
  }

  // Initialize when DOM is ready
  $(function() {
    // Set up event handlers
    $('.pagination-section .previous-page')
      .off('click.navigate')
      .on('click.navigate', function() {
        const $button = $(this);
        if (!$button.hasClass('disabled')) {
          navigate('left', $button.closest('.pagination-section'));
        }
      });

    $('.pagination-section .next-page')
      .off('click.navigate')
      .on('click.navigate', function() {
        const $button = $(this);
        if (!$button.hasClass('disabled')) {
          navigate('right', $button.closest('.pagination-section'));
        }
      });

    // Handle window resize
    $(window)
      .off('resizeEnd.paginationArrows')
      .on('resizeEnd.paginationArrows', function() {
        $('.pagination-section').each(function() {
          navigate('none', $(this));
        });
      });

    // Initialize all pagination sections
    $('.pagination-section').each(function() {
      navigate('none', $(this));
    });
  });
}
export const getRecaptchaToken = (action) => {
  return new Promise((resolve, reject) => {
    const keyId = $('#ml-recaptcha-script').data('key');

    grecaptcha.enterprise.ready(async () => {
      try {
        const token = await grecaptcha.enterprise.execute(keyId, { action: action });
        resolve(token);
      } catch (error) {
        reject(error);
      }
    });
  });
};
